<template>
 <div id="foot">
   <el-row class='footNav'>
     <el-col :span="12">
        <img src="../assets/images/tel.png" alt="">
     </el-col>
     <el-col :span="12">
       <div class="footMemu">
         <ul>
           <li v-for="(item, index) in navData" :key='item.navLink + index' :class="($route.name && $route.name.indexOf(item.navLink)) > -1 ? 'active' : ''" @click="$router.push({name:item.navLink})">{{item.navName}}</li>
         </ul>
       </div>
     </el-col>
   </el-row>
   <div class="center">
     地址: 郴州市北湖区燕泉街道燕泉路中天大厦内   &emsp;&emsp; <a href="https://beian.miit.gov.cn/ " target="_blank">沪ICP备15046583号-11</a>
   </div>
<!--   <p>友情链接：<a v-for="(item,index) in linkArr" :key="index" :href="item.link" target="_blank">{{item.name}}</a></p>-->
 </div>
</template>
<script>
export default {
  data () {
    return {
      linkArr: [],
      navData: [
        { id: 0, navName: '首页', navLink: 'Index', isShow: true },
        { id: 5, navName: '关于我们', navLink: 'about', isShow: true },
        { id: 1, navName: '产品中心', navLink: 'Products', isShow: true },
        // { id: 2, navName: '解决方案', navLink: 'Solutions', isShow: true },
        { id: 3, navName: '知识库', navLink: 'Articles', isShow: true },
        { id: 2, navName: '新闻中心', navLink: 'News', isShow: true },
        { id: 4, navName: '联系我们', navLink: 'Contactus', isShow: true }
      ],
      activeIndex: ''
    }
  },
  watch: {
  },
  methods: {
    getLinks () {
      this.$store.dispatch('getLinks').then(res => {
        this.linkArr = res
      })
    }
  },
  mounted () {
    this.getLinks()
  }
}
</script>
<style lang="less">
#foot {
  max-width: 1180px;
  margin: 0 auto;
  text-align: center;
  color: @global-white;
  p{
    margin: 0;
    margin-bottom: 1rem;
    span {
      margin-left: .5rem;
    }
  }
  p:last-child {
    margin-bottom: 0;
    a {
      margin-left: 1rem;
    }
  }
  a {
    color: @global-white;
  }
  .footNav{
    padding:32px 0 21px 0;
  }
  .center{
    color:#ccc; font-size:14px; line-height:2.1;
  }
  .footMemu{
    .el-menu{background:transparent;
      &.el-menu--horizontal{border-bottom:0}
    }
  }

  .el-menu--horizontal>.el-menu-item{
    color:#fff;
  }
  .footMemu{
    text-align: right;
    ul{
      margin:0; padding:0;
      display: flex; justify-content: flex-end;
      li{width:calc(100% / 6); line-height: 42px; font-size: 14px; cursor: pointer; text-align: left;
       &.active{color:rgb(24, 159, 249); border-bottom:0}
      }
    }
  }
}

  @media screen and (max-width: 768px) {
    #foot {
      padding: 1rem;
      /*font-size: .5rem;*/
      p{
        /*margin-bottom: 0;*/
      }
      .footNav{
        .el-col-12{
          width:100%;
        }
        img{
          max-width:100%
        }
        .footMemu{
          margin-right:0;
          ul{
            justify-content: space-around;
            flex-wrap: wrap;
          }
          ul li{
            width:calc(26%);
            text-align: center;
            margin:0;
          }
        }
      }
    }
  }
</style>

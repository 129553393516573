<template>
  <div id="app">
    <el-container>
      <el-header><Header /></el-header>
      <el-main :style="{minHeight:minH + 'px'}"><router-view /></el-main>
      <el-footer><Footer /></el-footer>
    </el-container>
  </div>
</template>
<script>
// import './assets/less/common.less'
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
export default {
  components: {
    Header,
    Footer
  },
  data () {
    return {
      minH: 0
    }
  },
  mounted () {
    if (sessionStorage.getItem('store')) {
      this.$store.replaceState(Object.assign({}, this.$store.state.storageId, JSON.parse(sessionStorage.getItem('store'))))
    }
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('store', JSON.stringify(this.$store.state))
    })
    this.minH = document.documentElement.clientHeight - 320
    let that = this
    window.onresize = function () {
      that.minH = document.documentElement.clientHeight - 320
    }
  }
}
</script>

<style lang="less">
.el-header {
  height:auto !important
}
.el-main {
  padding: 0;
  min-height: 14rem;
}
.el-footer {
  padding: 0;
  margin-top:1.8rem;
  background: #161D30;
  height:159px !important;
}
@media screen and (max-width: 900px) {
  .el-main{
    overflow-x: hidden;
  }
  .el-header{
    padding:0
  }
  .el-footer{
    height:auto !important;
  }
}
</style>

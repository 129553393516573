import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  linkActiveClass: 'active',
  routes: [
    {
      path: '/test',
      name: 'test',
      component: () => import('./views/Test.vue')
    },
    // 首页
    {
      path: '/',
      name: 'Index',
      component: () => import('./views/Index.vue')
    },
    // 产品中心
    {
      path: '/products',
      name: 'Products',
      component: () => import('./views/Product.vue')
    },
    // 登录注册
    {
      path: '/login',
      name: 'Login',
      component: () => import('./views/Login.vue')
    },
    // 人才管理
    // {
    //   path: '/hr',
    //   name: 'Talent',
    //   component: () => import('./views/Talent.vue')
    // },
    // // 财税管理
    // {
    //   path: '/tax',
    //   name: 'Financial',
    //   component: () => import('./views/Financial.vue')
    // },
    // // 总裁事务
    // {
    //   path: '/ceo',
    //   name: 'Cpractice',
    //   component: () => import('./views/Cpractice.vue')
    // },
    // 文库
    {
      path: '/articles',
      name: 'Articles',
      component: () => import('./views/Articles.vue')
    },
    // 联系我们
    {
      path: '/contact',
      name: 'Contactus',
      component: () => import('./views/Contactus.vue')
    },
    // 关于我们
    {
      path: '/about',
      name: 'about',
      component: () => import('./views/aBout.vue')
    },
    // VIP
    {
      path: '/vip',
      name: 'Vip',
      component: () => import('./views/Vip.vue')
    },
    // 个人中心
    {
      path: '/user',
      // name: 'personal',
      meta: { requireAuth: true },
      component: () => import('./views/Personal/index.vue'),
      children: [
        // 我的主页
        {
          path: '',
          name: 'UserHome',
          component: () => import('./views/Personal/Ihomepage.vue')
        },
        // 我的信息
        {
          path: '/info',
          name: 'UserInfo',
          component: () => import('./views/Personal/Imessage.vue')
        },
        // 修改密码
        {
          path: '/updatepwd',
          name: 'Updatepwd',
          component: () => import('./views/Personal/Updatepwd.vue')
        },
        // 发布文章
        {
          path: '/publish',
          name: 'Publish',
          component: () => import('./views/Personal/Publisharticles.vue')
        },
        // 我的积分
        {
          path: '/ipoints',
          name: 'Ipoints',
          component: () => import('./views/Personal/Ipoints.vue')
        },
        // 交易记录
        {
          path: '/transactions',
          name: 'Transactions',
          component: () => import('./views/Personal/Transactions.vue')
        },
        // 申请入驻
        {
          path: '/tenants',
          name: 'Tenants',
          component: () => import('./views/Personal/Tenants.vue')
        },
        // 观看记录
        {
          path: '/viewrecord',
          name: 'Viewrecord',
          component: () => import('./views/Personal/Viewrecord.vue')
        },
        // 员工观看记录
        {
          path: '/eviewrecord',
          name: 'Eviewrecord',
          component: () => import('./views/Personal/Eviewrecord.vue')
        },
        // 部门管理
        {
          path: '/department',
          name: 'Department',
          component: () => import('./views/Personal/Department.vue')
        },
        // 员工管理
        {
          path: '/employees',
          name: 'Employees',
          component: () => import('./views/Personal/Employees.vue')
        }
      ]
    },
    // 人才管理详情，财税管理详情，总裁事务详情
    {
      path: '/solutions',
      name: 'Solutions',
      component: () => import('./views/Solutions.vue')
    },
    {
      path: '/solutions/:id',
      name: 'Solutiondetail',
      component: () => import('./views/Detail.vue')
    },
    // 新闻详情
    {
      path: '/news',
      name: 'News',
      component: () => import('./views/news.vue'),
    },
    // 新闻详情
    {
      path: '/news/:id',
      name: 'Newsdetail',
      component: () => import('./views/Detail.vue')
    },
    // 产品案例详情
    {
      path: '/products/:id',
      name: 'Productdetail',
      component: () => import('./views/Detail.vue')
    },
    // 文库详情
    {
      path: '/articles/:id',
      name: 'Librarydetail',
      meta: { requireAuth: true },
      component: () => import('./views/LibraryDetail.vue')
    },
    // 文章详情（商家）
    {
      path: '/storedetail/:id',
      name: 'StoreDetail',
      meta: { requireAuth: true },
      component: () => import('./views/LibraryDetail.vue')
    },
    // 购买商品
    {
      path: '/buygoods/:id',
      name: 'Buygoods',
      meta: { requireAuth: true },
      component: () => import('./views/BuyGoods.vue')
    },
    // 支付
    {
      path: '/paygoods',
      name: 'Paygoods',
      meta: { requireAuth: true },
      component: () => import('./views/Vip.vue')
    }
  ]
})

router.beforeEach((to, from, next) => {
  let token = store.getters.getToken
  if (token) {
    if (to.name === 'Login') {
      next('/')
    }
    next()
  } else {
    if (to.matched.some(recode => recode.meta.requireAuth)) { // 判断该路由是否需要登录权限
      // console.log(to.fullPath)
      store.commit('setLastPath', to.fullPath)
      router.push({ name: 'Login' })
      // next('/Login')
    } else {
      next()
    }
  }
})
export default router

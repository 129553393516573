export const getToken = (state) => {
  if (state.login.LbjtAccessToken && state.login.LbjtTokenType) {
    return state.login.LbjtAccessToken + ' ' + state.login.LbjtTokenType
  }
  if (localStorage.LbjtAccessToken && localStorage.LbjtTokenType) {
    return localStorage.LbjtTokenType + ' ' + localStorage.LbjtAccessToken
  }
  return null
}
export const getLastPath = state => {
  return localStorage.LbjtlastPath || state.lastPath
}
export const showDialog = state => {
  return state.show
}

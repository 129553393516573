export function setLoginToken (state, params) {
  state.login.LbjtAccessToken = params.access_token
  state.login.LbjtTokenType = params.token_type
  state.login.LbjtExpiresTime = params.expires_in
  // lcoalStorage
  localStorage.LbjtAccessToken = params.access_token
  localStorage.LbjtTokenType = params.token_type
  localStorage.LbjtExpiresTime = params.expires_in
}
export function syncToken (state, params) {
  state.login = params
}
export function setLastPath (state, path) {
  state.lastPath = path
  localStorage.LbjtlastPath = path
}
export function logout (state) {
  state.login = {}
  localStorage.removeItem('LbjtAccessToken')
  localStorage.removeItem('LbjtTokenType')
  localStorage.removeItem('LbjtExpiresTime')
  localStorage.removeItem('LbjtlastPath')
}

// 存储 文章id 和 订单id
export function getParams (state, params) {
  console.log(params)
  if (params.orderId) {
    state.storageId.orderId = params.orderId
  }
  state.storageId.articleId = params.articleId
}

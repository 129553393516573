import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import * as actions from './store/actions'
import * as mutations from './store/mutations'
import * as getters from './store/getters'
import router from './router'
import { Message } from 'element-ui'
Vue.use(Vuex)
Vue.prototype.$http = axios
// 添加请求拦截器
axios.interceptors.request.use(function (config) {
  config.headers.Accept = `application/json`
  config.headers.ContentType = `application/x-www-form-urlencoded`
  if (localStorage.getItem('LbjtTokenType') || localStorage.getItem('LbjtAccessToken')) {
    config.headers.Authorization = localStorage.getItem('LbjtTokenType') + ' ' + localStorage.getItem('LbjtAccessToken')
  }
  return config
}, function (err) {
  console.log(err)
  return Promise.reject(err)
})

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  return response
}, function (error) {
  // 对响应错误做点什么
  const { status } = error.response
  // 如果是401报错 就删除token
  if (status === 401) {
    store.commit('logout')
    router.push({ name: 'Login' })
  } else if (status === 422) {
    Message({
      message: JSON.stringify(error.response.data.errors),
      type: 'error'
    })
    // alert(JSON.stringify(error.response.data.errors))
  }
  return Promise.reject(error)
})

let state = {
  storageToken: null,
  lastPath: null,
  login: {},
  // 存储订单id 和 文章id
  storageId: {}
}
// console.log('state:', state.storageId)
const store = new Vuex.Store({
  state,
  mutations,
  actions,
  getters
})
export default store
